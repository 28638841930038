<template>
  <div class="single-address" :class="{'active-address':active}">
    <div class="inner-single-address" v-if="address">
      <div v-if="address.company">
        {{address.company}}
      </div>
      <div>
        {{address.firstname}} {{address.lastname}}
      </div>
      <div>
        <span v-for="street in address.street" :key="street">
          {{street}}
        </span>
      </div>
      <div>
        {{address.postcode}} {{address.city}}
      </div>
      <div>
        {{address.country_id}}
      </div>
      <div v-if="address.vat_id">
        {{address.vat_id}}
      </div>
      <div class="v-button v-primary v-centered" v-if="checkout && !active">
        <a href="choose" @click.prevent="chooseAddress">
          {{$t('checkout.chooseAddress')}}
        </a>
      </div>
      <div class="active-check-container" v-if="active">
        <font-awesome-icon icon="check"></font-awesome-icon>
      </div>

    </div>
    <div class="empty-address-container" v-else>
      No address
    </div>

  </div>
</template>

<script>
export default {
  name: "AddressDisplay",
  props: ["address","active","checkout"],
  methods: {
    chooseAddress() {
        this.$emit('chooseAddress',this.address);
    }
  }
}
</script>

<style scoped>

</style>