<template>
  <div class="single-cart-item">
    <div class="sci-inner" v-if="product">
      <div class="sci-img">
        <router-link :to="{name:'ProductDetail',params:{sku:this.sku}}">
          <img :src="this.getImageUrl()" alt="product_image" @error="getPlaceHolderUrl"/>
        </router-link>
      </div>
      <div class="sci-text-container">
        <div class="sci-name-text">
          <div class="sci-name">
            {{ product.name }}
          </div>
          <div class="sci-sku">
            <router-link :to="{name:'ProductDetail', params:{sku:this.sku}}">
              {{$t("SKU")}}: {{ sku }}
            </router-link>
          </div>
        </div>
        <div class="sci-lower-text-container">
          <div class="sci-qty">
            <input type="number" v-model="qty" @change="changeQty" />
          </div>
          <div class="sci-remove" >
            <font-awesome-icon icon="trash" @click="deleteCartItem" />
          </div>
<!--          <div class="sci-revert" v-if="item_changed">-->
<!--            <font-awesome-icon icon="arrow-rotate-left" />-->
<!--          </div>-->
          <div class="sci-price">
            {{ price }} <br> <span class="price_add_text" v-if="is_real_price_price" >{{$t("product.price_add_text")}}</span>
          </div>
        </div>
        <div class="sci-delivery_date" v-if="replace_shipping">
          {{ $t('product.delivery_date_without_add') }} {{ delivery_time }}
        </div>
        <div class="sci-delivery_date" v-else>
          {{ $t('product.delivery_date') }} {{ delivery_time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductService from "@/services/product.service";
import CartService from "@/services/cart.service";
import apiConfig from "@/config/apiConfig";

export default {
  name: "SingleCartItem",
  props:["item"],
  data() {
    return {
      qty: 1,
      product: false,
      sku:0,
      media_url: apiConfig.getMediaUrl(),
      is_real_price_price: true,
      is_real_price: true,
      is_real_price_at: true,
      attributes: [],
      item_changed: false,
      delivery_time: "",
      replace_shipping: false
    }
  },
  async created() {
    console.log("ITEM:");
    console.log(this.item);
    this.qty = this.item.qty;
    this.sku = this.item.sku;
    ProductService.getProducts(this.$t('config.store_code'), this.sku)
        .then(products => {
          console.log(products);
          if(products.data.items.length > 1){
            this.product = false;
          } else {
            this.product = products.data.items[0];
          }
          this.isLoading = false;
          this.updateAppAttributes();
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        })
  },
  methods: {
    deleteCartItem() {
      CartService.deleteCartItem(this.item.item_id).then(
          () => {
            this.emitter.emit('changedCart', {'changedCart': true})
          }
      );
    },
    changeQty() {
      if (this.qty !== this.item.qty) {
        this.item_changed = true;
        let cartItem = {
          "sku": this.product.sku,
          "qty": this.qty,
          "quote_id": this.item.quote_id,
          "item_id": this.item.item_id
        }
        CartService.updateCartItemQty(cartItem).then(
            () => {
              this.emitter.emit('changedCart', {'changedCart': true})
          }
        )
      } else {
        this.item_changed = false;
      }
    },
    getImageUrl() {
      let img_url = this.media_url;
      let found = false;
      if (this.product) {
        console.log('productaaaa');
        console.log(this.product);
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(this.product.custom_attributes)) {
          this.attributes[value.attribute_code] = value.value;
          if (value.attribute_code === 'image') {
            img_url = img_url + "" + value.value
            found = true
          }
          if (value.attribute_code === 'short_description') {
            this.short_description = value.value
          }
        }
        if (found) {
          return img_url;
        } else {
          return apiConfig.getPlaceholderUrl();
        }
      }
    },
    getPlaceHolderUrl(event){
      event.target.src = apiConfig.getPlaceholderUrl();
    },
    async updateAppAttributes() {
      if (this.product) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(this.product.custom_attributes)) {
          this.attributes[value.attribute_code] = value.value;
        }
        if (this.attributes['app_price']) {
          console.log("app_price");
          console.log(this.attributes['app_price']);
          if(this.attributes['app_price'] == 0) {
            this.price = this.$t('product.on_inquiry');
            this.is_real_price_price = false;
          } else {
            this.price = ProductService.toCurrency(this.attributes['app_price']);
            this.price = ProductService.toCurrency(this.item.price);
          
          }
        } else {
          this.price = ProductService.toCurrency(this.product.price);
          var custom_price = await ProductService.getCustomPrice(this.$t('config.website_id'), this.product.id);
            if(custom_price.data){
              this.price = ProductService.toCurrency(custom_price.data[0].price);
            }
        }
        if (this.attributes["app_price_available_till"]) {
          this.price_validity = ProductService.formatDate(this.attributes["app_price_available_till"]);
        }
        if (this.attributes["app_price_group"]) {
          this.price_group = this.attributes["app_price_group"];
        }
        if (this.attributes['app_delivery_price']) {
          if(this.attributes['app_delivery_price'] == 0) {
            this.shipping_cost = this.$t('product.on_inquiry');
            this.is_real_price = false;
          } else {
            this.shipping_cost = ProductService.toCurrency(this.attributes['app_delivery_price']);
          }
        }
        if (this.attributes['app_delivery_price_at']) {
          if(this.attributes['app_delivery_price_at'] == 0) {
            this.shipping_cost_at= this.$t('product.on_inquiry');
          } else {
            this.shipping_cost_at = ProductService.toCurrency(this.attributes['app_delivery_price_at']);
          }
        }
        if (this.attributes['app_catalog_page']) {
          this.catalog_page = this.attributes['app_catalog_page'];
        }
        if(process.env.CEMO_REPlACE_SHIPMENT == 1){
          this.delivery_time = process.env.CEMO_REPLACE_SHIPMENT_DE;
          this.replace_shipping = true;
        } else {
          if (this.attributes['delivery_time']) {
            this.delivery_time = this.attributes['delivery_time'];
          } 
        }
      }
    }
  }
}
</script>
