<template>
  <div class="view-cart">
    <div class="view-container">
      <div class="cart-container" v-if="areCartItemsSet()">
        <single-cart-item v-for="item in cart.items" :key="item.item_id" :item="item" />
      </div>
      <div class="empty-cart-container" v-else>
        {{ $t("cart.emptyCart") }}
      </div>
   
      <!-- Add coupon code section -->

      <div  v-if="areCartItemsSet() && activateDiscountCode === '1'">
        <div class="coupon-form">
          <input 
            type="text" 
            v-model="couponCode" 
            :placeholder="$t('cart.coupon_placeholder')"
            :disabled="loading"
          />
          <div class="coupon-buttons">
            <div class="v-button v-primary">
              <button 
              
              @click="applyCoupon" 
              :disabled="!couponCode || loading"
            >
              {{ $t('cart.apply_coupon') }}
            </button>
            </div>
        
          </div>
        </div>
        <div v-if="couponMessage" :class="['coupon-message', couponMessage.type]">
          {{ couponMessage.text }}
        </div>
      </div>

      <div class="cart-totals-container" v-if="areCartItemsSet()">
        <CartTotal />
      </div>
      <div class="v-button v-primary v-centered" v-if="areCartItemsSet()">
        <router-link to="/checkout">
          {{$t('cart.checkout_button')}}
        </router-link>
      </div>
      <div class="v-button v-primary v-centered" v-else>
        <router-link to="/">
          {{$t('backToHome')}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SingleCartItem from "@/components/partials/cart/SingleCartItem";
import CartTotal from "@/components/partials/cart/CartTotal";
import CartService from "@/services/cart.service";
import { useToast } from "vue-toastification";
import apiConfig from "@/config/apiConfig";

export default {
  name: "Cart",
  setup() {
    const toast = useToast();
    return { toast }
  },
  computed:{
    cartIsNotEmpty(){
      if(this.cart.items){
        if(this.cart.items > 0 ) {
          return true;
        }
      }
      return false;
    }
  },
  components: {CartTotal, SingleCartItem},
  data() {
    return{
      cart: this.$store.state.cart.cart,
      cartTotals: false,
      couponCode: '',
      loading: false,
      couponMessage: null,
      activateDiscountCode: 0
    }
  },
  methods:{
    setDiscountCode(){
      this.activateDiscountCode = apiConfig.getActivateDiscountCode();
    },
    areCartItemsSet(){
      if(this.cart){
        if(this.cart.items){
          if(this.cart.items.length > 0){
            return true;
          }
        }
      }
      return false;
    },
    async applyCoupon() {
      this.loading = true;
      this.couponMessage = null;
      
      try {
        await CartService.applyCoupon(this.couponCode);
        this.toast.success(this.$t('cart.coupon_applied'));
        this.couponCode = '';
        await this.refreshCart();
      } catch (error) {
        this.toast.error(this.$t('cart.coupon_error'));
      } finally {
        this.loading = false;
      }
    },
    async removeCoupon() {
      this.loading = true;
      this.couponMessage = null;
      
      try {
        await CartService.deleteCoupon();
        this.toast.success(this.$t('cart.coupon_removed'));
        await this.refreshCart();
      } catch (error) {
        this.toast.error(this.$t('cart.coupon_remove_error'));
      } finally {
        this.loading = false;
      }
    },
    async refreshCart() {
      const cart = await CartService.getCart();
      this.cart = cart;
      this.emitter.emit('changedCart', {'changedCart': true});
    }
  },
  created() {
    this.cart = this.$store.state.cart.cart;
    if(!this.cart) {
      CartService.getCart().then(
        cart => {
          this.cart = cart;
        }
      )
    }
  },
  mounted() {
    this.setDiscountCode();
    this.emitter.on('changedCart', () => {
      CartService.getCart().then(
        cart => {
          this.cart = cart;
        }
      )
    })
  }
}
</script>

