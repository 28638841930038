<template>
  <div class="cart-total">
      <template  v-for="segment in cartTotals.total_segments" :key="segment.code">
          <div class="single-cart-total" :class="'total-'+segment.code" v-if="segment.value!=0">
              <div class="cart-total-label">
                  {{segment.title}}
                  <span v-if="segment.code == 'discount'">
                    <button @click="removeCoupon">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                    </button>
                  </span>
              </div>
              <div class="cart-total-value">
                  {{toCurrency(segment.value)}}
              </div>
          </div>
      </template>
  </div>
</template>

<script>
import CartService from "@/services/cart.service";
import ProductService from "@/services/product.service";
import CustomerService from "@/services/customer.service";
import { useToast } from "vue-toastification";
export default {
  name: "CartTotal",
  setup() {
    const toast = useToast();
    return { toast }
  },
  data() {
    return {
      cartTotals: false,
      cart: this.$store.state.cart.cart,
      customer: this.$store.state.customer.customerobj,
      shippingAddress:this.$store.state.checkout.checkoutobj.shippingAddress,
        rerender: 0
    }
  },
  computed: {
      loggedIn() {
          return this.$store.state.auth.status.loggedIn;
      },
  },
  created() {
    this.cart = this.$store.state.cart.cart;
    if(this.loggedIn){
        if(!this.shippingAddress) {
            CustomerService.getShippingAddress().then(
                shippingAdress => {
                    this.shippingAddress = shippingAdress;
                    delete this.shippingAddress.region;
                    delete this.shippingAddress.default_billing;
                    delete this.shippingAddress.default_shipping;
                    this.getCartTotals();
                }
            )
        } else {
            this.getCartTotals();
        }
    } else {
        this.getCartTotals();
    }

    if(!this.cart) {
      CartService.getCart().then(
          cart => {
            this.cart = cart;
          }
      )
    }

  },
  mounted() {
    this.emitter.on('changedCart', () => {
     this.getCartTotals();
    })
  },
  methods: {
    toCurrency(value) {
      return ProductService.toCurrency(value);
    },
    getCartTotals() {
      if(this.loggedIn) {
          let addressInformation= {
              address: this.shippingAddress
          };
          CartService.estimateShippingMethods(this.shippingAddress).then(
              shippingMethods => {
                  console.log(shippingMethods);
                  if(shippingMethods.length > 1){
                      console.log("more found")
                  } else {
                      addressInformation.shipping_carrier_code = shippingMethods[0].carrier_code;
                      addressInformation.shipping_method_code = shippingMethods[0].method_code;

                  }
                  CartService.getCartTotalsInformation(addressInformation).then(
                      cartTotals => {
                          console.log("cartTotals");
                          console.log(cartTotals);
                          this.cartTotals = cartTotals;
                      }
                  )
              }
          );
      } else {
          CartService.getCartTotals().then(
              cartTotals => {
                  console.log("cartTotals Guest");
                  console.log(cartTotals);
                  this.cartTotals = cartTotals;
                  this.rerender = this.rerender++;
              }
          )
      }


    },
    async removeCoupon() {
      this.loading = true;
      this.couponMessage = null;
      
      try {
        await CartService.deleteCoupon();
        this.toast.success(this.$t('cart.coupon_removed'));
        await this.getCartTotals();
      } catch (error) {
        this.toast.error(this.$t('cart.coupon_remove_error'));
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>